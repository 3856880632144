import './App.css';
import BackgroundImages from './BackgroundImages';
import laura from './assets/laura.svg';
import zheng from './assets/zheng.svg';

import {useState} from 'react';

function App() {
  const [isEmailHovered, setIsEmailHovered] = useState(false);
  const EMAIL = 'teamlauraz@gmail.com';
  return (
    <div className="page-wrapper">
      <section className="homepage-container">
        <div className="name">
          <img src={laura} alt="laura" />
          <img src={zheng} alt="zheng" />
        </div>
        <nav>
          <div id="email"
            className="copy-email"
            onClick={() => {
              document.getElementById('email').classList.add('clicking');

              //copy email to clipboard
              const textarea = document.createElement('textarea');
              textarea.value = EMAIL;
              textarea.readOnly = true;
              document.body.appendChild(textarea);
              textarea.focus();
              textarea.select();
              document.execCommand('copy');
              document.body.removeChild(textarea);

              setTimeout(() => {
                document.getElementById('email').classList.remove('clicking');
              }, 400)
            }}
            onMouseEnter={() => setIsEmailHovered(true)}
            onMouseLeave={() => setIsEmailHovered(false)}
          >
            <p>
              {isEmailHovered ? 'Copy Email' : EMAIL}
            </p>
          </div>
          <EnterPassword />
        </nav>
      </section>
      <BackgroundImages />
    </div>
  );
}

function EnterPassword() {
  return (
    <div className="password-wrapper">
      <form
        id="password"
        spellCheck="false"
        onSubmit={(e) => {
          e.preventDefault()
          document.getElementById('password').classList.add('nonono')
          setTimeout(() => {
            document.getElementById('password').classList.remove('nonono')
          }, 360)
        }}
      >
        <input
          type="password"
          placeholder="Enter Password"
        />
      </form>
    </div>
  );
}

export default App;
