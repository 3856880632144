import './BackgroundImages.css';
import {useEffect, useState} from 'react';
import lozad from 'lozad';

/**
* LAYOUTS
* dog-and-nyfw: top left + bottom right
* yeezy: full screen
* nyfw-row: full screen row
* piggyback: on the side
*/

const ASSETS = [
  {
    images: [
      'https://images.ctfassets.net/pf3x4o4oje95/60CUoXxN1KgszvR1ahjGmD/c8bb1b5ee34c4408927b7e6af0bf5245/dog.png',
      'https://videos.ctfassets.net/pf3x4o4oje95/3ouv0DSsFfMsyvCN7OxzfZ/9360dd19009fcffadec316f33046cd7c/2-loop.mov'
    ],
    duration: 6,
    layout: 'dog-and-nyfw'
  },
  {
    images: [
      'https://images.ctfassets.net/pf3x4o4oje95/1ZdAmyctVkDWZkR16mAsbO/eee531081cd969e6890661864af5ac5c/3-yeezy.jpg'
    ],
    duration: 6,
    layout: 'yeezy'
  },
  {
    images: [
      'https://images.ctfassets.net/pf3x4o4oje95/3iwvD96UvzBplVgzFVvXYc/16784d5af6ee9413ff1dbad3810072a6/4-1.jpg',
      'https://images.ctfassets.net/pf3x4o4oje95/NSIeBDeddgpQXxEeAiDVs/60ce37af969b51c5d34ef7dd50954aee/4-2.jpg',
      'https://images.ctfassets.net/pf3x4o4oje95/5GOeTczoeuss7OMnlMJqrd/ee39a3dc2c4aaf2abd1acd5ed61fc5cb/4-3.jpg',
      'https://images.ctfassets.net/pf3x4o4oje95/27sikX5HseGyoO0EyjXvah/415318ca1d50e1dec8150e017ff02cee/4-4.jpg',
      'https://images.ctfassets.net/pf3x4o4oje95/6p1Yugj0EIAUv4TgYQVxdC/9f2a8b061fe30b230656081c01593e52/4-5.jpg'
    ],
    duration: 6,
    layout: 'nyfw-row'
  },
  {
    images: [
      'https://videos.ctfassets.net/pf3x4o4oje95/3n6XprMkuTnKOA4iRZBERL/f982dc1c4a6d90ab954ecab202d89e0e/5.mov'
    ],
    duration: 11,
    layout: 'piggyback'
  },
]

function BackgroundImages() {
  const observer = lozad(
    '.lozad', {
      load: (el) => {
        if (el.src !== el.dataset.src) {
          el.src = el.dataset.src;
        }
      }
  });

  useEffect(() => {
    observer.observe();
  })

  // eslint-disable-next-line
  const [currIndex, setCurrIndex] = useState(0);
  const STAGGER_INT = 0.5;

  let assets = ASSETS[currIndex].images.map((asset, i) => {
    const {duration} = ASSETS[currIndex];
    const isMovie = asset.slice(-1) === 'v';
    const style = {
      animation: `background-fade ${duration}s ease-in-out`,
      animationDelay: `${i * STAGGER_INT + STAGGER_INT}s`
    }

    return isMovie ? (
      <video data-src={asset} playsInline
        className="lozad bg-img"
        key={`video-${currIndex}-${i}`}
        autoPlay muted controls={false}
        style={style}
      />
    ) : (
      <img key={`img-${currIndex}-${i}`}
        alt={`img-${i}`}
        className="lozad bg-img"
        data-src={asset}
        style={style}
      />
    );
  });

  useEffect(() => {
    const {images, duration} = ASSETS[currIndex];
    setTimeout(() => {
      setCurrIndex((curr) => ((curr + 1) % ASSETS.length))
    }, (duration + (STAGGER_INT*images.length)) * 1000)
  }, [currIndex])

  return (
    <div className={`background-images ${ASSETS[currIndex].layout}`}>
      {assets}
    </div>
  );
}

export default BackgroundImages;
